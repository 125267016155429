<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row v-if="!preloader">
      <v-col cols="12">
        <Patcher
          :itemValue="data.isActive"
          :previewValue="data.isActive"
          itemName="isActive"
          itemLabel="Der Channel ist aktiv"
          :hideLabel="true"
          :route="patchRoute"
          inputElement="input"
          inputType="checkbox"
          @emit="data.isActive = $event.value"
        ></Patcher>
        <Patcher
          :itemValue="data.name"
          itemName="name"
          itemLabel="Titel"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="input"
          inputType="text"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="data.name"
          @emit="data.name = $event.value"
        ></Patcher>
      </v-col>

      <v-col cols="12">
        <ChannelMessages :channelId="channelId" />
      </v-col>

      <v-col cols="12">
        <ImportChannelMembers :channelId="channelId" />
      </v-col>

      <v-col cols="12">
        <ChannelMembersList :channelId="channelId" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Patcher from "@/components/ui/Patcher.vue";
import ChannelMessages from "@/components/addons/info_mails_channels/ChannelMessages.vue";
import ImportChannelMembers from "@/components/addons/info_mails_channels/ImportChannelMembers.vue";
import ChannelMembersList from "@/components/addons/info_mails_channels/ChannelMembersList.vue";
export default {
  name: "_id",

  components: {
    PageHeader,
    Patcher,
    ChannelMessages,
    ImportChannelMembers,
    ChannelMembersList,
  },

  data() {
    return {
      pageTitle: "Info-Mail Channel",
      pageDescription: `<p>Bearbeitung der Info-Mail-Channel Daten</p>`,
      title: "",
      preloader: false,
      data: [],
      channelId: this.$route.params.id,
      patchRoute: `addons/infoMails/channels/${this.$route.params.id}`,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async deleteItem() {
      /* da ich die ID und den INDEX vorher den currentXX Vars zugewiesen habe, kann ich diese nun verwenden */
      let response = await this.deleteRequest(
        "addons/infoMails/channels/" + this.currentItemId
      );
      if (response.status === 200) {
        this.items.splice(this.currentItemIndex, 1); // remove from array
      }
    },

    dialogEmit(value) {
      if (value) {
        /* ANSWER: TRUE */

        this.deleteItem();
        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde erfolgreich gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else {
        /* ANSWER: FALSE */

        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde nicht gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async createNew() {
      /* create new entry */
      let response = await this.postRequest("addons/infoMails/channels"); // await data from mixin

      let newItem = response.data.data;
      this.items.splice(0, 0, newItem); // add to array at index 0

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Ein neuer Bonus wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */
        this.currentItemId = value.itemId;
        this.currentItemIndex = value.itemIndex;
        this.dialog = true; // starte den Dialog. Rückgabe erfolgt via emit nach dialogEmit()
      } else if (value.action === "editItem") {
        this.componentKey += 1;
        this.itemData = value.item;
        this.itemIndex = value.index;
        this.detailDialog = true;
      }
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        `addons/infoMails/channels/${this.channelId}`
      );

      if (response) {
        this.data = response.data.data;
      }

      this.preloader = false;
    },
  },

  mixins: [apiRequest],
};
</script>
