<template>
  <v-card class="mb-2">
    <v-toolbar color="secondary" dark dense>
      <v-toolbar-title>Channel Members</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-if="mounted">
      <!-- Button to reFetchData -->
      <v-btn depressed color="success" class="mb-2" @click="reFetchData"
        >Refetch</v-btn
      >

      <DataTable
        v-bind:items="items"
        v-bind:headers="headers"
        :preloader="preloader"
      />

      <!-- <pre>{{ items }}</pre> -->
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import DataTable from "@/components/ui/DataTable.vue";
export default {
  name: "ChannelMembersList",
  props: ["channelId"],
  components: {
    DataTable,
  },

  data() {
    return {
      items: null,
      preloader: false,
      mounted: false,
      headers: [
        { text: "ID", value: "id", width: "80px" },
        { text: "created", value: "created", width: "200px" },
        { text: "Firma", value: "companies_id", width: "80px" },
        { text: "Kampagne", value: "campaigns_id", width: "80px" },
        { text: "Lead-ID", value: "leads_id", width: "80px" },
        { text: "E-Mail", value: "email" },
      ],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    reFetchData() {
      this.getData();
    },

    async getData() {
      /* 
      Options:
      TEST UPDATE
      Route GET /addons/infoMails/members mit weiteren Query-Parametern, z.B:
      ?filter[channels_id]=15
      ?filter[imports_id]=20
      ?filter[campaigns_id]=20
      ?filter[companies_id]=15
      */

      this.preloader = true;
      let response = await this.getRequest(
        `addons/infoMails/members?filter[channels_id]=${this.channelId}`
      );
      this.items = response.data.data;
      this.preloader = false;
      this.mounted = true;
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
